import React from "react";
import HeaderSection from "../Header Section";
import SkillsItem from "../Skills Item";
import "./style.scss";

import icon_frontend from "../../assets/icons/icon_front-end.svg";
import icon_backend from "../../assets/icons/icon_back-end.svg";
import icon_outros from "../../assets/icons/icon_outros.svg";

const Skills = () => {
  const array_tags_frontend = [
    "HTML5",
    "SCSS",
    "CSS3",
    "Next.js",
    "JavaScript",
    "React.js",
    "Typescript",
    "Styled-components",
  ];
  const array_tags_backend = ["Node.JS", "MongoDB", "Express.js", "Typescript"];
  const array_tags_outros = ["Git", "Github", "Gitlab", "Figma"];
  return (
    <section className="container skills-block" id="skills">
      <HeaderSection title="Minhas Habilidades" color="header-section-blue">
        Essas são as minhas habilidades e um pouco do que eu uso durante o
        desenvolvimento de um site
      </HeaderSection>
      <div className="skills-block-content content">
        <SkillsItem
          title="Front-End"
          description="Essas são algumas tecnologias que eu uso durante o desenvolvimento do front-end de um website."
          icon={icon_frontend}
          tags={array_tags_frontend}
        />
        <SkillsItem
          title="back-End"
          description="Essas são algumas tecnologias que eu uso durante o desenvolvimento do back-end de um website."
          icon={icon_backend}
          tags={array_tags_backend}
        />
        <SkillsItem
          title="Outros"
          description="Algumas das ferramentas que ultilizo durante o desenvolvimento e prototipação de um projeto."
          icon={icon_outros}
          tags={array_tags_outros}
        />
      </div>
    </section>
  );
};

export default Skills;
