import React from "react";
import Tags from "../Tags";
import "./style.scss";

interface IProps {
  title: string;
  icon: string;
  description: string;
  tags: Array<string>;
}
const SkillsItem: React.FC<IProps> = ({
  title,
  icon,
  description,
  tags,
  ...props
}) => {
  return (
    <section className="skills-item-block">
      <div className="skills-item-content">
        <div className="skills-item-header-block">
          <img src={icon} alt={title} />
          <h2 className="skills-item-title">{title}</h2>
        </div>
        <div className="skills-item-text-block">
          <p className="skills-item-text">{description}</p>
        </div>
        <div className="skills-tags-block">
          <Tags tags={tags} />
        </div>
      </div>
    </section>
  );
};

export default SkillsItem;
