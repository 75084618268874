import React from "react";
import ReactGA from "react-ga";
import "./style.scss";

import arrow_down from "../../assets/icons/icon_arrow-down.svg";
import Skills from "../../components/Skills";
import Works from "../../components/Works";
import Footer from "../../components/Footer";
import Presentation from "../../components/Presentation";
import ContactSection from "../../components/Contact Section";

function initializeReactGA() {
  ReactGA.initialize("UA-159351404-1");
  ReactGA.pageview("/");
}
const Home = () => {
  initializeReactGA();
  return (
    <section className="home-box">
      <section className="home-box-hero">
        <div className="home-box-image"></div>
        <h2 className="home-box-title title">
          Desenvolvedor <strong>Front-end</strong>
        </h2>
        <a href="#quemSou" className="home-box-image-arrow-down">
          <img src={arrow_down} alt="" />
        </a>
      </section>
      <Presentation />
      <Skills />
      <Works />
      <Footer>
        <ContactSection />
      </Footer>
    </section>
  );
};

export default Home;
