import React from "react";

export interface IContentModalWork {
  title: string;
  description: string;
  image: string;
  tags: Array<string>;
  url_repo?: string | null;
  url_project?: string | null;
  IsDevelopment: boolean;
}
interface GlobalContent {
  openModal?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  contentModal?: IContentModalWork;
  setContentModal?: React.Dispatch<React.SetStateAction<IContentModalWork>>;
}
export const GlobalContext = React.createContext<GlobalContent>({});

export const GlobalStorage: React.FC = ({ children }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [contentModal, setContentModal] = React.useState({
    title: "",
    description: "",
    image: "",
    tags: ["teste"],
    IsDevelopment: false,
  });
  return (
    <GlobalContext.Provider
      value={{ openModal, setOpenModal, contentModal, setContentModal }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
