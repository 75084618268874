import React from 'react'
import { GlobalContext, IContentModalWork } from '../GlobalStorage'


const UseModal = () => {
    const {setOpenModal, openModal, contentModal, setContentModal} = React.useContext(GlobalContext);
    const OpenModal = (content:IContentModalWork) => {
        setContentModal?.(content);
        setOpenModal?.(true);
        return
    }
    const CloseModal = () => {
        setContentModal?.({
            title:'',
            description: '',
            image:'',
            tags:[''],
            url_repo:'',
            url_project:'',
            IsDevelopment:false
        });
        setOpenModal?.(false);
        return
    }
    return {
        OpenModal,
        CloseModal
    }
}

export default UseModal