import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Presentation = () => {
  return (
    <section className="presentation container" id="quemSou">
      <div className="presentation-content">
        <div className="presentation-block">
          <h1 className="presentation-block-title title">
            Olá, eu sou <strong>Luan!</strong>
          </h1>
          <p className="presentation-block-text text">
            Eu me chamo Luan e sou apaixonado por tecnologia, gosto de criar
            soluções para problemas utilizando a programação. Atualmente procuro
            um emprego para Desenvolvedor Front-end. Tenho interesse em crescer
            na área de tecnologia aprender novas linguagens e criar muitos
            projetos legais. Com o tempo muita dedicação eu chego lá.
          </p>
        </div>
        <div className="presentation-block-links">
          <a
            href="#skills"
            className="presentation-block-links__link presentation-block-links__link--contrast"
          >
            Ver Skills
          </a>
          <a
            href="/#projetos"
            className="presentation-block-links__link presentation-block-links__link--light"
          >
            Projetos
          </a>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
