import React from "react";
import "./style.scss";

const ContactSection = () => {
  return (
    <section className="contactSection-block container">
      <div className="contactSection-content">
        <div className="contactSection-item-left">
          <h1>Achou interesante?</h1>
          <p>Entre em contato! Me envie uma mensagem agora mesmo!</p>
        </div>
        <div className="contactSection-item-right">
          <a href="/#contato">Entrar em contato</a>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
