import React from "react";
import "./style.scss";

interface IPros{
  title:string,
  color:string
}

const HeaderSection:React.FC<IPros> = ({title, color, children}) => {
  return (
    <header className="header-section-block container" id={color}>
    <div className="content header-section-content">
      <h1 className="title header-section-title">
       {title}
      </h1>
      <p className="text header-section-text">
        {children}
      </p>
    </div>
  </header>
  );
};

export default HeaderSection ;
