import React from "react";
import HeaderSection from "../Header Section";
import WorkItem from "../Work Item";
import DataWorks from "./dataWorks";

import "./style.scss";

const Works = () => {
  const data = DataWorks;
  return (
    <section className="works-block container" id="projetos">
      <HeaderSection title="Meus trabalhos" color="header-section-light">
        Aqui estão alguns de meus projetos pessoais
      </HeaderSection>
      <div className="works-block-content content">
        {data.map((item) => (
          <WorkItem workData={item} key={item.title} />
        ))}
      </div>
    </section>
  );
};

export default Works;
