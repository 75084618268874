import React from "react";
import "./style.scss";
import ReactGA from "react-ga";

import { GlobalContext, IContentModalWork } from "../../GlobalStorage";
import UseModal from "../../Hooks/UseModal";
// import url from '../../assets/img/'
interface IProps {
  workData: IContentModalWork;
}
const WorkItem: React.FC<IProps> = ({ workData }) => {
  const modal = UseModal();
  return (
    <div
      className="work-Item-block"
      onClick={() => {
        modal.OpenModal(workData);
        ReactGA.event({
          category: "view",
          action: "click",
          label: "Visualizou: " + workData.title,
        });
      }}
    >
      <span className="work-item-span-container">
        <span className="work-item-span">
          <p>{workData.description}</p>
          <button
            type="button"
            onClick={() => {
              modal.OpenModal(workData);
              ReactGA.event({
                category: "view",
                action: "click",
                label: "Visualizou: " + workData.title,
              });
            }}
          >
            Mais Informações
          </button>
        </span>
        <img src={workData.image} alt="Projetos" className="work-item-image" />
      </span>
    </div>
  );
};

export default WorkItem;
