import React from "react";
import "./style.scss";

interface FooterProps {
  children: React.ReactNode;
}
const svg_github = (
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2132 17C21.6874 17 17.2072 21.5904 17.2072 27.2534C17.2072 31.7834 20.0742 35.626 24.0507 36.9825C24.5514 37.0762 24.7338 36.7596 24.7338 36.4877C24.7338 36.2448 24.7252 35.5996 24.7203 34.7442C21.9368 35.3636 21.3496 33.3695 21.3496 33.3695C20.8943 32.1856 20.2383 31.8702 20.2383 31.8702C19.3297 31.2339 20.3071 31.2465 20.3071 31.2465C21.3115 31.3195 21.8398 32.3033 21.8398 32.3033C22.7324 33.8699 24.1822 33.4174 24.7523 33.1555C24.8432 32.4927 25.1012 32.0408 25.3875 31.7846C23.1655 31.5259 20.8292 30.646 20.8292 26.7171C20.8292 25.598 21.2193 24.6828 21.8594 23.9659C21.7562 23.7066 21.4128 22.6642 21.9571 21.2524C21.9571 21.2524 22.7975 20.9767 24.7093 22.3036C25.5073 22.0763 26.3636 21.9624 27.2145 21.9586C28.0641 21.9624 28.9204 22.0763 29.7196 22.3036C31.6302 20.9767 32.4687 21.2524 32.4687 21.2524C33.0148 22.6642 32.6714 23.7066 32.5682 23.9659C33.2096 24.6828 33.5972 25.598 33.5972 26.7171C33.5972 30.6561 31.2573 31.5228 29.0279 31.7765C29.3873 32.0931 29.7074 32.7187 29.7074 33.6754C29.7074 35.0457 29.6951 36.1516 29.6951 36.4877C29.6951 36.7622 29.8751 37.0813 30.3831 36.9812C34.3565 35.6223 37.2211 31.7821 37.2211 27.2534C37.2211 21.5904 32.7402 17 27.2132 17Z"
      fill="#CFDEE4"
    />
    <path
      d="M53.9836 27.5C53.9836 41.9967 42.2233 53.75 27.7145 53.75C13.2057 53.75 1.44543 41.9967 1.44543 27.5C1.44543 13.0033 13.2057 1.25 27.7145 1.25C42.2233 1.25 53.9836 13.0033 53.9836 27.5Z"
      stroke="#5495AC"
      strokeWidth="2.5"
    />
  </svg>
);
const svg_linkedin = (
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4173 16C18.088 16 17.012 17.0772 17.0118 18.4025C17.0118 19.7292 18.0877 20.8061 19.4175 20.8061C20.7436 20.8061 21.821 19.7292 21.821 18.4025C21.821 17.0769 20.7434 16 19.4173 16Z"
      fill="#CFDEE4"
    />
    <path
      d="M21.4903 22.6292H17.3423V35.9655H21.4903V22.6292Z"
      fill="#CFDEE4"
    />
    <path
      d="M32.0502 22.2976C30.0325 22.2976 28.6795 23.4031 28.1257 24.4515H28.0702V22.6292H24.092H24.0917V35.9653H28.236V29.3679C28.236 27.6286 28.5674 25.9439 30.7254 25.9439C32.8524 25.9439 32.8808 27.9331 32.8808 29.4794V35.9651H37.0257V28.6503C37.0257 25.0597 36.2504 22.2976 32.0502 22.2976Z"
      fill="#CFDEE4"
    />
    <path
      d="M53.7881 27.5C53.7881 41.9967 42.0279 53.75 27.5191 53.75C13.0102 53.75 1.25 41.9967 1.25 27.5C1.25 13.0033 13.0102 1.25 27.5191 1.25C42.0279 1.25 53.7881 13.0033 53.7881 27.5Z"
      stroke="#5495AC"
      strokeWidth="2.5"
    />
  </svg>
);
const svg_whatapp = (
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.24 19.9205C32.3586 18.0382 29.8565 17.0011 27.1909 17C21.6982 17 17.2279 21.4671 17.2257 26.9574C17.2249 28.7125 17.6838 30.4258 18.5558 31.936L17.1421 37.0962L22.4248 35.7114C23.8804 36.5048 25.5191 36.923 27.1868 36.9235H27.191C32.6831 36.9235 37.1539 32.456 37.156 26.9654C37.1571 24.3045 36.1215 21.8026 34.24 19.9205ZM27.1909 35.2417H27.1874C25.7013 35.2411 24.2437 34.842 22.9719 34.088L22.6695 33.9085L19.5347 34.7304L20.3714 31.6761L20.1744 31.3629C19.3453 30.0451 18.9075 28.5219 18.9082 26.9581C18.9099 22.3946 22.6256 18.6819 27.1942 18.6819C29.4065 18.6826 31.4861 19.5446 33.0499 21.1091C34.6136 22.6736 35.4743 24.7532 35.4735 26.9648C35.4716 31.5286 31.7562 35.2417 27.1909 35.2417ZM31.7341 29.0427C31.4851 28.9181 30.2609 28.3164 30.0326 28.2332C29.8045 28.1502 29.6384 28.1088 29.4725 28.3578C29.3065 28.6068 28.8294 29.1674 28.684 29.3333C28.5387 29.4994 28.3936 29.5202 28.1446 29.3956C27.8955 29.2711 27.0933 29.0083 26.1422 28.1606C25.402 27.5008 24.9023 26.686 24.757 26.437C24.6119 26.1878 24.7558 26.0661 24.8663 25.9292C25.1358 25.5947 25.4057 25.244 25.4887 25.0781C25.5718 24.912 25.5302 24.7667 25.4678 24.6422C25.4057 24.5177 24.9077 23.2929 24.7003 22.7946C24.498 22.3096 24.2929 22.3751 24.14 22.3675C23.9949 22.3602 23.8288 22.3588 23.6628 22.3588C23.4968 22.3588 23.227 22.421 22.9987 22.6702C22.7706 22.9193 22.1274 23.5212 22.1274 24.7459C22.1274 25.9707 23.0196 27.1538 23.1441 27.3199C23.2685 27.486 24.8999 29.9992 27.3976 31.0769C27.9916 31.3335 28.4554 31.4864 28.8171 31.6011C29.4136 31.7905 29.9563 31.7638 30.3854 31.6997C30.8638 31.6282 31.8584 31.0977 32.0661 30.5166C32.2735 29.9353 32.2735 29.4372 32.2112 29.3333C32.149 29.2295 31.983 29.1674 31.7341 29.0427Z"
      fill="#CFDEE4"
    />
    <path
      d="M53.9184 27.5C53.9184 41.9967 42.1582 53.75 27.6494 53.75C13.1406 53.75 1.38031 41.9967 1.38031 27.5C1.38031 13.0033 13.1406 1.25 27.6494 1.25C42.1582 1.25 53.9184 13.0033 53.9184 27.5Z"
      stroke="#5495AC"
      strokeWidth="2.5"
    />
  </svg>
);
const svg_email = (
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.423 20H35.7461C37.0394 20 38.0915 21.0514 38.0915 22.3438V32.3438C38.0915 33.6361 37.0394 34.6875 35.7461 34.6875H20.423C19.1298 34.6875 18.0776 33.6361 18.0776 32.3438V22.3438C18.0776 21.0514 19.1298 20 20.423 20ZM35.7461 33.125C36.1772 33.125 36.5279 32.7745 36.5279 32.3438V22.8224L28.084 29.0987L19.6412 22.8232V32.3438C19.6412 32.7745 19.9919 33.125 20.423 33.125H35.7461ZM20.5651 21.5625L28.084 27.1513L35.603 21.5625H20.5651Z"
      fill="#CFDEE4"
    />
    <path
      d="M53.8533 27.5C53.8533 41.9967 42.0931 53.75 27.5842 53.75C13.0754 53.75 1.31519 41.9967 1.31519 27.5C1.31519 13.0033 13.0754 1.25 27.5842 1.25C42.0931 1.25 53.8533 13.0033 53.8533 27.5Z"
      stroke="#5495AC"
      strokeWidth="2.5"
    />
  </svg>
);
const Footer = ({ children }: FooterProps) => {
  return (
    <section className="footer-block container">
      {children}
      <div className="footer-content content">
        <div className="footer-item-title">
          <h2>
            <span>{"<"}</span> luan miqueias <span>{">"}</span>
          </h2>
          <p>
            Ser desenvolvedor é uma viagem onde a próxima parada é a solução de
            um problema.
          </p>
        </div>
        <div className="footer-item-social-media" id="contato">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/luanmiqueias/"
                target="blank"
              >
                {svg_linkedin}
              </a>
            </li>
            <li>
              <a href="mailto:luanmiqueias92@gmail.com">{svg_email}</a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=5519999372315">
                {svg_whatapp}
              </a>
            </li>
            <li>
              <a href="https://github.com/luanmiqueias" target="blank">
                {svg_github}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-block-info">
        Feito por mim <span className="footer-block-info-dot" />
        <strong>Luan Miqueias</strong>
      </div>
    </section>
  );
};

export default Footer;
