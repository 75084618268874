import React from "react";
import "./style.scss";

import logo from "../../assets/icons/logo-moblie.svg";

const Header = () => {
  const [pageTop, setPageTop] = React.useState("");
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 180) {
        return setPageTop("navShadow");
      } else {
        return setPageTop("");
      }
    });
  }, []);
  return (
    <header className={`${pageTop} header-block`}>
      <div className="content">
        <img src={logo} alt="" className="logo" />
        <nav className="nav-main">
          <a href="#quemSou">Quem sou?</a>
          <a href="#skills">Skills</a>
          <a href="#projetos">Projetos</a>
        </nav>
        <a
          href="https://github.com/LuanMiqueias"
          target="blank"
          className="logo-github"
        >
          <span className="icon github-icon-header">
            <svg
              width="35"
              height="35"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2132 0C4.68743 0 0.207214 4.59042 0.207214 10.2534C0.207214 14.7834 3.07424 18.626 7.05071 19.9825C7.55138 20.0762 7.73384 19.7596 7.73384 19.4877C7.73384 19.2448 7.72523 18.5996 7.72032 17.7442C4.93685 18.3636 4.34956 16.3695 4.34956 16.3695C3.89435 15.1856 3.23826 14.8702 3.23826 14.8702C2.32968 14.2339 3.30706 14.2465 3.30706 14.2465C4.31147 14.3195 4.83979 15.3033 4.83979 15.3033C5.73239 16.8699 7.18218 16.4174 7.75226 16.1555C7.84318 15.4927 8.1012 15.0408 8.38747 14.7846C6.16548 14.5259 3.82923 13.646 3.82923 9.71711C3.82923 8.59799 4.21932 7.6828 4.85944 6.96588C4.75624 6.70656 4.41283 5.66423 4.95712 4.25242C4.95712 4.25242 5.79751 3.97673 7.70926 5.30356C8.50726 5.07634 9.36362 4.96241 10.2145 4.95864C11.0641 4.96241 11.9204 5.07634 12.7196 5.30356C14.6302 3.97673 15.4687 4.25242 15.4687 4.25242C16.0148 5.66423 15.6714 6.70656 15.5682 6.96588C16.2096 7.6828 16.5972 8.59799 16.5972 9.71711C16.5972 13.6561 14.2573 14.5228 12.0279 14.7765C12.3873 15.0931 12.7074 15.7187 12.7074 16.6754C12.7074 18.0457 12.6951 19.1516 12.6951 19.4877C12.6951 19.7622 12.8751 20.0813 13.3831 19.9812C17.3565 18.6223 20.2211 14.7821 20.2211 10.2534C20.2211 4.59042 15.7402 0 10.2132 0Z"
                fill="#CFDEE4"
              />
            </svg>
          </span>
        </a>
      </div>
    </header>
  );
};

export default Header;
