import img_quizapp from "../../assets/img/img_quizapp.jpg";
import img_apiquizapp from "../../assets/img/img_apiquizapp.jpg";
import img_astros from "../../assets/img/img_astros.jpg";
import img_f1dashboard from "../../assets/img/img_f1dashboard.jpg";
import img_squadManager from "../../assets/img/img_squadManager.jpg";
import img_pixel from "../../assets/img/pixel.jpg";
import img_astronomy from "../../assets/img/astronomy.jpg";

const DataWorks = [
  {
    title: "Pixel Editor",
    description:
      "(Em desenvolvimento) Um editor de Pixel Art feito com NextJS e Typescript com objetivo principal de criar uma maneira simples imagens 2D, podendo pintar e escolher a cor de cada pixel.",
    image: img_pixel,
    tags: ["NextJS", "Typescript", "Scss"],
    url_repo: "https://github.com/LuanMiqueias/pixel-editor",
    url_project: "https://pixel-editor-theta.vercel.app/",
    IsDevelopment: true,
  },
  {
    title: "Astronomy News",
    description:
      "Uma demonstração de um blog com foco em astronomia, com várias categorias e  no backend foi usado NodeJs e Mongodb junto de um CMS chamado Strapi para gerenciar os posts e as categorias.",
    image: img_astronomy,
    tags: ["NextJS", "Typescript", "NodeJS", "Mongodb", "Strapi"],
    url_repo: "https://github.com/LuanMiqueias/astronomy-blog",
    url_project: "http://astronomy-blog.vercel.app/",
    IsDevelopment: false,
  },
  {
    title: "Squad Management Tool",
    description:
      "Este projeto tem o objetivo de criar times de futebol onde poder escolhido desde a formação do time até a posição dos jogadores arrastando e soltando no lugar desejado. Os jogadores estão sendo buscados atravez de uma API gratuita - https://www.api-football.com/ Para mais informações de como foi feito, visite o repositorio do projeto e veja o README",
    image: img_squadManager,
    tags: ["ReactJS", "Javascript", "API"],
    url_repo: "https://github.com/LuanMiqueias/squad-Management",
    url_project: "https://squad-management-iota.vercel.app/",
    IsDevelopment: false,
  },
  {
    title: "F1 Dashboard",
    description:
      "Este site tem seu pricipal objetivo manter o usuario informado sobre a formula 1, como por exemplo ver o melhores jogadores, ou até então verificar as competições e corridas que estão agendadas. Nesse projeto ultilizei uma API externa gratuita chamada Api-sports e nextJS + typescript e também ultilizei o getStaticProps do Next pra atualizar as principais informações do site a cada 24H.",
    image: img_f1dashboard,
    tags: ["Typescript", "NextJS", "API", "Testes", "Em Desenvolvimento"],
    url_repo: "https://github.com/LuanMiqueias/f1-dashboard",
    url_project: "https://f1-dashboard.vercel.app/",
    IsDevelopment: false,
  },
  {
    title: "Astros",
    description:
      "Esse é um projeto novo que ainda estou desenvolvendo, seu objetivo é ser um local onde astronomos possam postar suas astrofotografias e falar a respeito delas. Algumas de suas funcionalidades por enquanto é: Scroll Infinito(API do pixabay para receber as imagens por enquanto), Um modal que pode ser reutilizado em todo o site.",
    image: img_astros,
    tags: [
      "Typescript",
      "Next.JS",
      "Scroll Infinito",
      "API externa",
      "Em Desenvolvimento",
    ],
    url_repo: "https://github.com/LuanMiqueias/astros",
    url_project: "https://astros.vercel.app/",
    IsDevelopment: false,
  },
  {
    title: "Quiz App",
    description:
      "Esse é o meu primeiro projeto completo em ReactJS que está consumindo um API que eu mesmo fiz! Ainda em desenvolvimento. Minha intenção foi deixa-lo bem simples e facil de se usar, ele tem como principal objetivo criar quizzes com o tema e as perguntas que o usuario desejar, e tambem de responder quizzes feitos por outras pessoas.",
    image: img_quizapp,
    tags: ["ReactJS", "Javascript", "NodeJS", "MongoDB"],
    url_repo: "https://github.com/LuanMiqueias/quiz-app",
    url_project: "http://quizapp.luanmiqueias.com.br/",
    IsDevelopment: false,
  },
  // {
  //   title: "API - Quiz App",
  //   description:
  //     "Essa é minha primeira API nela é possivel fazer login usando JWT, cadastros de usuarios, listar os quizzes cadastrados no banco, ver historico e também excluir seu propio quiz.",
  //   image: img_apiquizapp,
  //   tags: ["MongoDB", "NodeJS", "Express.JS", "JWT", "Heroku"],
  //   url_repo: "https://github.com/LuanMiqueias/server-quizapp",
  //   url_project: "https://quizluan.herokuapp.com/",
  //   IsDevelopment: false,
  // },
];

export default DataWorks;
