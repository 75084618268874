import React from "react";
import "./style.scss";

interface IProps {
  tags: Array<string> | undefined;
  classNameTag?: string;
}

const Tags: React.FC<IProps> = ({ tags, classNameTag }) => {
  return (
    <>
      {tags &&
        tags.map((item) => {
          return (
            <span key={item} className={`${classNameTag} tag-item`}>
              {item}
            </span>
          );
        })}
    </>
  );
};

export default Tags;
