import React from 'react';
import {BrowserRouter, Route, Router, Switch} from 'react-router-dom'
import Header from './components/Header';
import Modal from './components/Modal';
import { GlobalStorage } from './GlobalStorage';

import Home from './pages/home';

function App() {
  return (
    <BrowserRouter>
      <GlobalStorage>
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
      </Switch>
      <Modal/>
      </GlobalStorage>
    </BrowserRouter>
  );
}

export default App;
