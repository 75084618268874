import { EventType } from "@testing-library/react";
import { TargetElement } from "@testing-library/user-event";
import React from "react";
import { GlobalStorage, GlobalContext } from "../../GlobalStorage";
import UseModal from "../../Hooks/UseModal";
import Tags from "../Tags";
import "./style.scss";
import iconClose from "../../assets/icons/icon_close.svg";
import iconTimer from "../../assets/icons/icon_timer.svg";

interface IGlobalStorage {
  openModal?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  teste?: string;
}
interface IProps {
  useGlobalContext: Function;
}
const Modal = () => {
  const modal = UseModal();
  const ModalRef = React.useRef<HTMLDivElement>(null);
  const { openModal, contentModal } = React.useContext(GlobalContext);
  // React.useMemo(() => {
  //   setOpenModal(!openModal)
  // },[])
  const handleModal = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).dataset.closeModal) {
      modal.CloseModal();
    }
  };
  return openModal ? (
    <div
      className="modal-block"
      ref={ModalRef}
      data-close-modal
      onClick={(e) => handleModal(e)}
    >
      <div className="modal-block-container content">
        <div className="modal-block-content">
          <button
            onClick={() => modal.CloseModal()}
            className="icon-closeModal"
          >
            <img src={iconClose} alt="close" />
          </button>
          <div className="modal-block-item-left-text">
            <h2>{contentModal?.title}</h2>
            <p>{contentModal?.description}</p>
          </div>
          <div className="modal-block-item-img"></div>
          <div className="modal-block-item-tags">
            <div className="content-tags">
              <Tags tags={contentModal?.tags} />
            </div>
          </div>
          <div className="modal-block-item-links">
            {!contentModal?.IsDevelopment ? (
              <>
                {contentModal?.url_project && (
                  <a
                    href={contentModal?.url_project}
                    className="modal-block-item-link--active"
                    target="blank"
                  >
                    Visitar site
                  </a>
                )}
              </>
            ) : contentModal?.url_project ? (
              <a
                href={contentModal?.url_project}
                className="modal-block-item-link--active"
                target="blank"
              >
                Visitar site (Em desenvolvimento)
              </a>
            ) : (
              <a href="/#" className="modal-block-item-link--disabled">
                <img src={iconTimer} alt="" height="22px" />
                Em desenvolvimento
              </a>
            )
            }

            {contentModal?.url_repo && (
              <>
                {contentModal?.url_repo ? (
                  <a
                    href={contentModal?.url_repo}
                    className="url-project"
                    target="blank"
                  >
                    Ir para repositorio
                  </a>
                ) : (
                  <a
                    href={contentModal?.url_repo}
                    className="modal-block-item-link--active"
                    target="blank"
                  >
                    Ir para repositorio
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
